import { Address } from "./../../../../commons/models/address";
import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from "@angular/forms";
import { MapsAPILoader } from "@agm/core";
import { BehaviorSubject } from "rxjs";
import { paths } from "../../../../helpers/poligon.maps";
import { environment } from "../../../../../environments/environment.local_ac";

export enum AddressInputType {
  ALL = "all",
  CITY = "city",
  GEOCODE = "geocode",
  ADDRESS = "address",
  REGION = "region", // - NOT IMPLEMENTED YET
  ESTABLISHMENT = "establishment", // - NOT IMPLEMENTED YET
}

const COORDS = environment.birgoCoords;
@Component({
  selector: "app-address-input",
  templateUrl: "./address-input.component.html",
  styleUrls: ["./address-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInputComponent), // forward the reference,
      multi: true // allow multiple component in the same form
    }
  ]
})
export class AddressInputComponent implements OnInit, ControlValueAccessor {

  public displayCustomAddressButton: boolean = false;

  @Input("placeholder")
  public placeholder: string;

  // MAPS
  @ViewChild("search")
  public searchElementRef: ElementRef;
  @Input("addressControl")
  public searchControl: FormControl;

  public addressControl: FormControl = new FormControl();

  public polygon: {} = paths;
  public defaultLat: number;
  public defaultLng: number;
  public defaultZoom: number;

  private _value: Address;

  private _type: AddressInputType = AddressInputType.ADDRESS;

  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();

  // Taken from https://stackoverflow.com/questions/49747278/angular-5-nested-form-child-component
  private _onChange = (_: any) => {
    this.displayCustomAddressButton = false;
  };
  private _onTouched = (_: any) => {};
  disabled = false;

  constructor(private ngZone: NgZone, private mapsAPILoader: MapsAPILoader) {}

  ngOnInit() {
    this.listenForPlaces();

    this.addressControl.valueChanges.subscribe(value => {
      this.updateCustomButton(value);
    })
  }

  private updateCustomButton(text: string) {
    this.displayCustomAddressButton = false;
    if (text) {
      if (!this.value || (this.value && text != this.value.street)) {
        this.displayCustomAddressButton = true;
      }
    }
  }

  writeValue(value: Address): void {
    if (value && value !== null) {
      this._value = value;
      this.updatePosition();
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: (_: any) => void): void {
    this._onTouched = fn; // didn't used it but you should for touch screen enabled devices.
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // MAPS

  private updatePosition() {
    if (!this.value) {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // this.loadingSubject.next(false);
            // this.defaultLat = Number(position.coords.latitude);
            // this.defaultLng = Number(position.coords.longitude);
            // this.defaultZoom = 11;
            this.setDefaultCoords();
          },
          (error) => {
            this.setDefaultCoords();
          }
        );
      } else {
        this.setDefaultCoords();
      }
    } else {
      // this.defaultLat = Number(this.value.latitude);
      // this.defaultLng = Number(this.value.longitude);
      // this.defaultZoom = 11;
      // this.loadingSubject.next(false);
      this.setDefaultCoords();
    }
  }

  private setDefaultCoords() {
    // Milan coords
    this.defaultLat = COORDS.lat;
    this.defaultLng = COORDS.lng;
    this.defaultZoom = 11;
    this.loadingSubject.next(false);
  }

  private listenForPlaces() {
    //create search FormControl
    this.searchControl = new FormControl();

    //set current position
    this.updatePosition();

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // More info for styling and customize options at --> https://developers.google.com/maps/documentation/javascript/places-autocomplete

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.setTypes(this.resolveGoogleSearchTypes());

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.value = Address.fromGoogleAddress(place);
        });
      });

    });
  }

  /**
   * GOOGLE SEARCH SUPPORTED TYPES
   *
   * - geocode
   * - address
   * - establishment
   * - (regions)
   * - (cities)
   *
   * more info at --> https://developers.google.com/places/supported_types
   *
   */
  private resolveGoogleSearchTypes(): string[] {
    let result = [];
    switch (this.type) {
      case AddressInputType.ALL: 
        break;
      case AddressInputType.GEOCODE:
        result.push("geocode");
        break;
      case AddressInputType.ADDRESS:
        result.push("address");
        break;
      case AddressInputType.ESTABLISHMENT:
        result.push("establishment");
        break;
      case AddressInputType.REGION:
        result.push("(regions)");
        break;
      case AddressInputType.CITY:
        result.push("(cities)");
        break;
    }
    return result;
  }

  valueFunction() {
    if (this.value) {
      switch (this.type) {
        case AddressInputType.ALL:
          return this.value.formattedAddress;
        case AddressInputType.GEOCODE:
          return [this.value.latitude, this.value.longitude];
        case AddressInputType.ADDRESS:
          return this.value.formattedAddress;
        // case AddressInputType.ESTABLISHMENT:
        // //NOT IMPLEMENTED
        //   return ""
        // case AddressInputType.REGION:
        //   return this.value.region;
        case AddressInputType.CITY:
          return this.value.city;
      }
    }
    return "";
  }

  set value(value: Address) {
    // interceptor for updating current value
    this._value = value;
    this.updatePosition();
    this._onChange(this._value);
  }

  get value() {
    return this._value;
  }

  public get type(): AddressInputType {
    return this._type;
  }

  @Input("type")
  public set type(type: AddressInputType) {
    this._type = type;
  }

  setCustomValue() {
    this.value = new Address({ street: this.addressControl.value });
  }

}
