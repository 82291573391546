import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { getItalianPaginatorIntl } from '../../helpers/italian.paginator';
import { MatGridListModule, MatCardModule, MatMenuModule, MatIconModule, MatButtonModule, MatTableModule, MatPaginatorModule, MatSortModule, MatProgressSpinnerModule, MatToolbarModule, MatPaginatorIntl, MatProgressBarModule, MatInputModule, MatTabsModule, MatTooltipModule, MatSidenavModule, MatCheckboxModule, MatButtonToggleModule, MatDividerModule, MatAutocompleteModule, MatExpansionModule, MatSlideToggleModule, MatDatepickerModule, MatNativeDateModule, MAT_DATE_LOCALE, MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule, MatDialogModule, MatSelectModule, MatBadgeModule, MatChipsModule, MatListModule, MatRadioModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatInputModule,
    MatTabsModule,
    MatTooltipModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatSelectModule,
    MatBadgeModule,
    MatChipsModule,
    MatListModule,
    MatRadioModule
  ],
  exports: [
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatInputModule,
    MatTabsModule,
    MatTooltipModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatDialogModule,
    MatSelectModule,
    MatBadgeModule,
    MatChipsModule,
    MatListModule,
    MatRadioModule
  ],
  declarations: [],
  providers: [
    { 
      provide: MatPaginatorIntl, 
      useValue: getItalianPaginatorIntl()
    },
    {
      provide: MAT_DATE_LOCALE, 
      useValue: 'it-IT'
    }
  ]

})
export class MaterialModule { }
