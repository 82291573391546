export class Base {
  private _id: number;
  private _createdAt: Date;
  private _updatetAt: Date;

  constructor(source: any) {
    if (source) {
      this._id = source.id;
      this._createdAt = new Date(source.created_at);
      this._updatetAt = new Date(source.updatet_at);
    }
  }

  public static compare(b1: Base, b2: Base): boolean {
    return b1 && b2 ? b1.id === b2.id : b1 === b2;
  }

  protected isToBeExcluded(fieldName: string, excludes?: string[]) {
    return excludes && excludes.indexOf(fieldName) > -1;
  }

  /**
   * Getter id
   * @return {string}
   */
  public get id(): number {
    return this._id;
  }

  /**
   * Setter id
   * @param {number} value
   */
  public set id(value: number) {
    this._id = value;
  }

  /**
   * Getter createdAt
   * @return {Date}
   */
  public get createdAt(): Date {
    return this._createdAt;
  }

  /**
   * Setter createdAt
   * @param {Date} value
   */
  public set createdAt(value: Date) {
    this._createdAt = value;
  }

  /**
   * Getter updatetAt
   * @return {Date}
   */
  public get updatetAt(): Date {
    return this._updatetAt;
  }

  /**
   * Setter updatetAt
   * @param {Date} value
   */
  public set updatetAt(value: Date) {
    this._updatetAt = value;
  }
}
