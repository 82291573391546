import * as moment from "moment";

const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
const PRINTER_DATE_FORMAT = "DD-MM-YYYY";

export function formatDateForBackend(date: Date) {
  return moment(date).format(BACKEND_DATE_FORMAT);
}

export function formatDateForPrinter(date: Date) {
  return moment(date).format(PRINTER_DATE_FORMAT);
}

export const IT_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};
