import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { AddressDTO } from "../../models/address";

@Injectable({
  providedIn: "root"
})
export class LaravelAddressService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      show: environment.baseUrl + "/api/address",
      store: environment.baseUrl + "/api/address",
      update: environment.baseUrl + "/api/address",
      delete: environment.baseUrl + "/api/address"
    };
  }

  public getById(id: number, include?: string[]): Observable<any> {
    let params = { id: "" + id };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public create(address: AddressDTO) {
    return this.httpClient.post(this.ROUTES.store, { address: address });
  }

  public update(address: AddressDTO) {
    return this.httpClient.put(this.ROUTES.store, { address: address });
  }

  public delete(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params: params });
  }
}
