import * as moment from "moment";
import { AbstractControl } from "@angular/forms";

const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
const BACKEND_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export function formatDateForBackend(date: Date, includeTime: boolean = false) {
  if (date) {
    return moment(date).format(includeTime ? BACKEND_DATETIME_FORMAT : BACKEND_DATE_FORMAT);
  }
  return null;
}

export function minArrayLength(min: number) {
  return (c: AbstractControl): { [key: string]: any } => {
    if (c.value.length >= min) return null;

    return { minArrayLength: { valid: false } };
  };
}
