import { Base } from "./base";
import { Address, AddressDTO } from "./address";
import { FormGroup } from "@angular/forms";

export interface CustomerDTO {
  id?: number;
  name?: string;
  last_name?: string;
  tel?: string;
  email?: string;
  level?: string;
  addresses?: AddressDTO[];
  orders_count?: number;
  orders_avg?: number;
  report?: string;
  notes?: string;
}
export class Customer extends Base {
  private _name: string;
  private _lastName: string;
  private _tel: string;
  private _email: string;
  private _level: string;
  private _addresses: Array<Address>;
  private _ordersCount: number;
  private _ordersAvg: number;
  private _report: string;
  private _notes: string;

  constructor(source: CustomerDTO) {
    super(source);
    if (source) {
      this.name = source.name;
      this.lastName = source.last_name;
      this.tel = source.tel;
      this.email = source.email;
      this.level = source.level;
      this.report = source.report;
      this.notes = source.notes;
      this.ordersCount = source.orders_count;
      this.ordersAvg = source.orders_avg;
      this.addresses = new Array<Address>();
      if (source.addresses) {
        source.addresses.forEach(address => {
          this.addresses.push(new Address(address));
        });
      }
    }
  }

  public toDTO(excludes?: string[]): CustomerDTO {
    let dto: CustomerDTO = {
      id: this.id,
      name: this.name,
      last_name: this.lastName,
      tel: this.tel,
      email: this.email,
      orders_count: this.ordersCount,
      orders_avg: this.ordersAvg,
      level: this.level,
      notes: this.notes
    };
    if (this.addresses && !this.isToBeExcluded("addresses", excludes)) {
      dto.addresses = [];
      this.addresses.forEach(address => {
        dto.addresses.push(address.toDTO());
      });
    }
    return dto;
  }

  static fromFormGroup(formGroup: FormGroup): Customer {
    const formModel = formGroup.value;
    let customer: Customer = new Customer(null);
    customer.name = formModel.name;
    customer.lastName = formModel.lastName;
    customer.email = formModel.email;
    customer.tel = formModel.telephone;
    customer.notes = formModel.notes;
    return customer;
  }

    /**
     * Getter name
     * @return {string}
     */
	public get name(): string {
		return this._name;
	}

    /**
     * Getter lastName
     * @return {string}
     */
	public get lastName(): string {
		return this._lastName;
	}

    /**
     * Getter tel
     * @return {string}
     */
	public get tel(): string {
		return this._tel;
	}

  /**
     * Getter email
     * @return {string}
     */
	public get email(): string {
		return this._email;
	}

    /**
     * Getter level
     * @return {string}
     */
	public get level(): string {
		return this._level;
	}

    /**
     * Getter addresses
     * @return {Array<Address>}
     */
	public get addresses(): Array<Address> {
		return this._addresses;
	}

    /**
     * Getter ordersCount
     * @return {number}
     */
	public get ordersCount(): number {
		return this._ordersCount;
	}

    /**
     * Getter ordersAvg
     * @return {number}
     */
	public get ordersAvg(): number {
		return this._ordersAvg;
	}

    /**
     * Getter report
     * @return {string}
     */
	public get report(): string {
		return this._report;
	}

    /**
     * Getter notes
     * @return {string}
     */
	public get notes(): string {
		return this._notes;
	}

    /**
     * Setter name
     * @param {string} value
     */
	public set name(value: string) {
		this._name = value;
	}

    /**
     * Setter lastName
     * @param {string} value
     */
	public set lastName(value: string) {
		this._lastName = value;
	}

    /**
     * Setter tel
     * @param {string} value
     */
	public set tel(value: string) {
		this._tel = value;
	}

      /**
     * Setter email
     * @param {string} value
     */
	public set email(value: string) {
		this._email = value;
	}

    /**
     * Setter level
     * @param {string} value
     */
	public set level(value: string) {
		this._level = value;
	}

    /**
     * Setter addresses
     * @param {Array<Address>} value
     */
	public set addresses(value: Array<Address>) {
		this._addresses = value;
	}

    /**
     * Setter ordersCount
     * @param {number} value
     */
	public set ordersCount(value: number) {
		this._ordersCount = value;
	}

    /**
     * Setter ordersAvg
     * @param {number} value
     */
	public set ordersAvg(value: number) {
		this._ordersAvg = value;
	}

    /**
     * Setter report
     * @param {string} value
     */
	public set report(value: string) {
		this._report = value;
	}

    /**
     * Setter notes
     * @param {string} value
     */
	public set notes(value: string) {
		this._notes = value;
	}

}
