import { Time } from '@angular/common';
import { FormGroup } from '@angular/forms';

import { formatDateForPrinter } from '../../helpers/date.helper';
import { formatDateForBackend } from '../../helpers/util';
import { Address, AddressDTO } from './address';
import { Base } from './base';
import { Customer, CustomerDTO } from './customer';
import { DeliveryMan, DeliveryManDTO } from './delivery-man';
import { ProductInOrder, ProductInOrderDTO } from './product-in-order';
import { Status, StatusDTO } from './status';
import { ZoneDTO } from './zone';

export type SweetStatus = 'pending' | 'accepted' | 'delivered';
export interface OrderDTO {
  id?: number;
  amount?: number;
  delivery_date?: string;
  delivery_time?: string;
  notes?: string;
  address?: AddressDTO;
  address_id?: number;
  customer?: CustomerDTO;
  customer_id?: number;
  discount?: number;
  delivery_man_id?: number;
  delivery_man?: DeliveryManDTO;
  status_id?: number;
  status?: StatusDTO;
  products_in_order?: ProductInOrderDTO[];
  eat_here?: boolean;
  sweet_status?: SweetStatus;
  deleted_at?: string;
}

export interface PrintableOrder {
  dailyProgressiveID?: number;
  amount?: number;
  discount?: number;
  totalQuantity?: number;
  address?: AddressDTO;
  zone?: ZoneDTO;
  customer?: CustomerDTO;
  deliveryDate?: string;
  deliveryTime?: string;
  printDate?: string;
  printTime?: string;
  notes?: string;
  productsInOrder?: ProductInOrderDTO[];
  isEdited?: boolean;
  shippingCost?: number;
  eatHere?: boolean;
}

const SHIPPING_COST = 2;
export class Order extends Base {
  private _dailyID: number;
  private _amount: number;
  private _discount: number;
  private _deliveryDate: Date;
  private _deliveryTime: string;
  private _notes: string;
  private _address: Address;
  private _customer: Customer;
  private _deliveryMan: DeliveryMan;
  private _status: Status;
  private _productsInOrder: Array<ProductInOrder>;
  private _eatHere: boolean;
  private _infoWindowVisible: boolean;
  private _shippedAt: Date;
  private _deliveredAt: Date;
  sweetStatus: SweetStatus;
  deletedAt: Date;

  constructor(source: any) {
    super(source);
    if (source) {
      this.dailyID = source.daily_id;
      this.amount = source.amount;
      this.discount = source.discount;
      this.deliveryDate = new Date(source.delivery_date);
      this.deliveryTime = source.delivery_time;
      this.notes = source.notes;
      this.address = source.address ? new Address(source.address) : null;
      this.customer = source.customer ? new Customer(source.customer) : null;
      this.deliveryMan = source.delivery_man ? new DeliveryMan(source.delivery_man) : null;
      this._status = source.status ? new Status(source.status) : null;
      this.productsInOrder = new Array<ProductInOrder>();
      if (source.products_in_order) {
        source.products_in_order.forEach(product => {
          this._productsInOrder.push(new ProductInOrder(product));
        });
      }
      this.eatHere = source.eat_here ? source.eat_here : false;
      this.infoWindowVisible = false;
      if (source.shipped_at) {
        this.shippedAt = new Date(source.shipped_at);
      }
      if (source.delivered_at) {
        this.deliveredAt = new Date(source.delivered_at);
      }
      this.sweetStatus = source.sweet_status || 'pending';
      if (source.deleted_at) {
        this.deletedAt = new Date(source.deleted_at);
      }
    }
  }

  updateAmount() {
    this.amount = 0;

    if (this.address) {
      this.amount = SHIPPING_COST;
    }

    if (this.productsInOrder) {
      this.productsInOrder.forEach(p => {
        this.amount = Number(this.amount) + Number(p.amount);
      });
    }
    this.amount = this.amount * ((100 - this.discount) / 100);
  }

  static fromFormGroup(formGroup: FormGroup): Order {
    const formModel = formGroup.value;
    let order: Order = new Order(null);
    order.deliveryDate = formModel.date;
    //order.deliveryTime = formModel.time;
    order.deliveryTime = `${formModel.hour}:${formModel.minute}:00`;
    order.discount = formModel.discount;
    order.notes = formModel.notes;
    order.eatHere = formModel.eatHere;
    return order;
  }

  public toDTO(excludes?: string[]): OrderDTO {
    let dto: OrderDTO = {
      id: this.id,
      amount: this.amount,
      discount: this.discount && this.discount > 0 ? Number(this.discount.toFixed(2)) : 0,
      delivery_date: formatDateForBackend(this.deliveryDate),
      delivery_time: this.deliveryTime,
      notes: this.notes,
      eat_here: this.eatHere,
      sweet_status: this.sweetStatus,
      deleted_at: this.deletedAt ? formatDateForBackend(this.deletedAt) : null
    };
    if (this.status && !this.isToBeExcluded("status", excludes)) {
      dto.status_id = this.status.id;
      dto.status = this.status.toDTO();
    }
    if (this.address && !this.isToBeExcluded("address", excludes)) {
      dto.address_id = this.address.id;
      dto.address = this.address.toDTO();
    }
    if (this.customer && !this.isToBeExcluded("customer", excludes)) {
      dto.customer_id = this.customer.id;
      dto.customer = this.customer.toDTO();
    }
    if (this.deliveryMan && !this.isToBeExcluded("deliveryMan", excludes)) {
      dto.delivery_man_id = this.deliveryMan.id;
      dto.delivery_man = this.deliveryMan.toDTO();
    }
    if (this.productsInOrder && !this.isToBeExcluded("productsInOrder", excludes)) {
      dto.products_in_order = [];
      this.productsInOrder.forEach(productInOrder => {
        dto.products_in_order.push(productInOrder.toDTO(["order"]));
      });
    }

    return dto;
  }

  public toPrintableOrder(isEdited: boolean): PrintableOrder {
    let printableOrder: PrintableOrder = {
      dailyProgressiveID: this.dailyID,
      amount: this.amount ? Number(Number(this.amount).toFixed(2)) : 0,
      discount: this.discount ? Number(Number(this.discount).toFixed(2)) : 0,
      totalQuantity: this.productsInOrder.map(p => p.quantity).reduce((totalQuantity, quantity) => totalQuantity + quantity),
      address: this.address ? this.address.toDTO() : null,
      zone: (this.address && this.address.zone) ? this.address.zone.toDTO() : null,
      customer: this.customer ? this.customer.toDTO() : null,
      deliveryDate: formatDateForPrinter(this.deliveryDate),
      deliveryTime: this.deliveryTime,
      printDate: formatDateForPrinter(new Date()),
      printTime: this.deliveryTime.substr(0, 5),
      notes: this.notes,
      isEdited: isEdited,
      shippingCost: Number(SHIPPING_COST.toFixed(2)),
      eatHere: this.eatHere
    };

    if (this.productsInOrder) {
      printableOrder.productsInOrder = [];
      let productsInOrder = this.productsInOrder;
      let drinksAndDesserts = productsInOrder.filter(p => p.product.type == 'Dessert' || p.product.type == 'Drink');
      let HamburgersAndSides = productsInOrder.filter(p => p.product.type != 'Dessert' && p.product.type != 'Drink');
      let orderedProducts = drinksAndDesserts.concat(HamburgersAndSides);
      orderedProducts.forEach(productInOrder => {
        printableOrder.productsInOrder.push(productInOrder.toDTO(["order"]));
      });
    }

    return printableOrder;
  }

  get archived(): boolean {
    return !!this.deletedAt;
  }

  /**
   * Getter dailyID
   * @return {number}
   */
  public get dailyID(): number {
    return this._dailyID;
  }

  /**
   * Getter amount
   * @return {number}
   */
  public get amount(): number {
    return this._amount;
  }

  /**
   * Getter discount
   * @return {number}
   */
  public get discount(): number {
    return this._discount;
  }

  /**
   * Getter deliveryDate
   * @return {Date}
   */
  public get deliveryDate(): Date {
    return this._deliveryDate;
  }

  /**
   * Getter deliveryTime
   * @return {Time}
   */
  public get deliveryTime(): string {
    return this._deliveryTime;
  }

  /**
   * Getter notes
   * @return {string}
   */
  public get notes(): string {
    return this._notes;
  }

  /**
   * Getter address
   * @return {Address}
   */
  public get address(): Address {
    return this._address;
  }

  /**
   * Getter customer
   * @return {Customer}
   */
  public get customer(): Customer {
    return this._customer;
  }

  /**
   * Getter deliveryMan
   * @return {DeliveryMan}
   */
  public get deliveryMan(): DeliveryMan {
    return this._deliveryMan;
  }

  /**
   * Getter status
   * @return {Status}
   */
  public get status(): Status {
    return this._status;
  }

  /**
   * Getter productsInOrder
   * @return {Array<ProductInOrder>}
   */
  public get productsInOrder(): Array<ProductInOrder> {
    return this._productsInOrder;
  }

  /**
   * Getter eatHere
   * @return {boolean}
   */
  public get eatHere(): boolean {
    return this._eatHere;
  }

  /**
   * Getter infoWindowVisible
   * @return {boolean}
   */
  public get infoWindowVisible(): boolean {
    return this._infoWindowVisible;
  }

  /**
   * Getter shippedAt
   * @return {Date}
   */
  public get shippedAt(): Date {
    return this._shippedAt;
  }

  /**
   * Getter deliveredAt
   * @return {Date}
   */
  public get deliveredAt(): Date {
    return this._deliveredAt;
  }

  /**
   * Setter dailyID
   * @param {number} value
   */
  public set dailyID(value: number) {
    this._dailyID = value;
  }

  /**
   * Setter amount
   * @param {number} value
   */
  public set amount(value: number) {
    this._amount = value;
  }

  /**
   * Setter discount
   * @param {number} value
   */
  public set discount(value: number) {
    this._discount = value;
  }

  /**
   * Setter deliveryDate
   * @param {Date} value
   */
  public set deliveryDate(value: Date) {
    this._deliveryDate = value;
  }

  public set deliveryTime(value: string) {
    this._deliveryTime = value;
  }

  /**
   * Setter notes
   * @param {string} value
   */
  public set notes(value: string) {
    this._notes = value;
  }

  /**
   * Setter address
   * @param {Address} value
   */
  public set address(value: Address) {
    this._address = value;
  }

  /**
   * Setter customer
   * @param {Customer} value
   */
  public set customer(value: Customer) {
    this._customer = value;
  }

  /**
   * Setter deliveryMan
   * @param {DeliveryMan} value
   */
  public set deliveryMan(value: DeliveryMan) {
    this._deliveryMan = value;
  }

  /**
   * Setter status
   * @param {Status} value
   */
  public set status(value: Status) {
    this._status = value;
  }

  /**
   * Setter productsInOrder
   * @param {Array<ProductInOrder>} value
   */
  public set productsInOrder(value: Array<ProductInOrder>) {
    this._productsInOrder = value;
  }

  /**
   * Setter eatHere
   * @param {boolean} value
   */
  public set eatHere(value: boolean) {
    this._eatHere = value;
  }

  /**
   * Setter infoWindowVisible
   * @param {boolean} value
   */
  public set infoWindowVisible(value: boolean) {
    this._infoWindowVisible = value;
  }

  /**
   * Setter shippedAt
   * @param {Date} value
   */
  public set shippedAt(value: Date) {
    this._shippedAt = value;
  }

  /**
   * Setter deliveredAt
   * @param {Date} value
   */
  public set deliveredAt(value: Date) {
    this._deliveredAt = value;
  }

  get sweetsInOrder(): ProductInOrder[] {
    return this.productsInOrder ? this.productsInOrder.filter(inOrder => inOrder.product.family == 'gelato') : [];
  }

  get hasSweets(): boolean {
    return this.sweetsInOrder.length > 0;
  }
}
