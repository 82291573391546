import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ios-banner-pwa',
  templateUrl: './ios-banner-pwa.component.html',
  styleUrls: ['./ios-banner-pwa.component.scss']
})
export class IosBannerPwaComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

  private isIos(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  }

  private isInStandaloneMode(): boolean {
    return ('standalone' in window.navigator) && (window.matchMedia('(display-mode: standalone)').matches);
  }

  showBanner(): boolean {
    return this.isIos() && !this.isInStandaloneMode();
  }

}
