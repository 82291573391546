import { Base } from "./base";
import { Ingredient, IngredientDTO } from "./ingredient";
import { AbstractControl } from "@angular/forms";

export interface VariationDTO {
  id?: number;
  type?: string;
  amount?: number;
  notes?: string;
  ingredient_id?: number;
  ingredient?: IngredientDTO;
}
export class Variation extends Base {
  private _type: string;
  private _amount: number;
  private _notes: string;
  private _ingredient: Ingredient;

  constructor(source: any) {
    super(source);
    if (source) {
      this.type = source.type;
      this.amount = source.amount ? source.amount : 0;
      this.notes = source.notes ? source.notes : null;
      this.ingredient = source.ingredient ? new Ingredient(source.ingredient) : null;
    }
  }

  public static same(v1: Variation, v2: Variation): boolean {
    if (v1 && v2) {
      if (v1.ingredient && v2.ingredient) {
        return v1.ingredient.id === v2.ingredient.id && v1.type === v2.type;
      }
      if (v1.notes && v2.notes) {
        return v1.notes === v2.notes;
      }
    }
    return false;
  }

  public toDTO(excludes?: string[]): VariationDTO {
    let dto: VariationDTO = {
      id: this.id,
      type: this.type,
      amount: this.amount,
      notes: this.notes
    };
    if (this.ingredient && !this.isToBeExcluded("ingredient", excludes)) {
      dto.ingredient_id = this.ingredient.id;
      dto.ingredient = this.ingredient.toDTO();
    }
    return dto;
  }

  static fromFormGroup(formGroup: AbstractControl): Variation {
    const formModel = formGroup.value;
    let variation: Variation = new Variation(null);
    variation.type = formModel.type;
    variation.amount = formModel.amount;
    variation.ingredient = formModel.ingredient;
    variation.notes = formModel.notes;
    return variation;
  }

  /**
   * Getter type
   * @return {string}
   */
  public get type(): string {
    return this._type;
  }

  /**
   * Getter amount
   * @return {number}
   */
  public get amount(): number {
    return this._amount;
  }

  /**
   * Getter notes
   * @return {string}
   */
  public get notes(): string {
    return this._notes;
  }

  /**
   * Getter ingredient
   * @return {Ingredient}
   */
  public get ingredient(): Ingredient {
    return this._ingredient;
  }

  /**
   * Setter type
   * @param {string} value
   */
  public set type(value: string) {
    this._type = value;
  }

  /**
   * Setter amount
   * @param {number} value
   */
  public set amount(value: number) {
    this._amount = value;
  }

  /**
   * Setter notes
   * @param {string} value
   */
  public set notes(value: string) {
    this._notes = value;
  }

  /**
   * Setter ingredient
   * @param {Ingredient} value
   */
  public set ingredient(value: Ingredient) {
    this._ingredient = value;
  }
}
