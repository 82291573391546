import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '../material/material.module';
import { environment } from './../../../environments/environment';
import { AddressInputComponent } from './components/address-input/address-input.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { IosBannerPwaComponent } from './components/ios-banner-pwa/ios-banner-pwa.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CurrencyLocalPipe } from './pipes/currency-local.pipe';

// Components
@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    RouterModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gMapsApiKey,
      libraries: ["places"]
    })
  ],
  exports: [NotFoundComponent, SpinnerComponent, AddressInputComponent, CurrencyLocalPipe, IosBannerPwaComponent, ReactiveFormsModule],
  declarations: [NotFoundComponent, SpinnerComponent, DialogComponent, AddressInputComponent, CurrencyLocalPipe, IosBannerPwaComponent]
})
export class SharedModule { }
