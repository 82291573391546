import { Address } from "./../models/address";
import { LaravelAddressService } from "./laravel/laravel-address.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { ListResult } from "../../helpers/listResult.interface";
import { Customer } from "../models/customer";
import { LaravelCustomerService } from "./laravel/laravel-customer.service";

@Injectable()
export class CustomerService {
  constructor(private laravelCustomerService: LaravelCustomerService, private laravelAddressService: LaravelAddressService) {}

  public getCustomer(id: number): Observable<Customer> {
    return this.laravelCustomerService.getById(id).pipe(
      map(response => {
        return new Customer(response);
      })
    );
  }

  public updateCustomer(customer: Customer): Observable<Customer> {
    return this.laravelCustomerService.update(customer.id, customer.name, customer.lastName, customer.tel,  customer.email, customer.notes).pipe(
      map(result => {
        return new Customer(result);
      })
    );
  }

  public createCustomer(customer: Customer): Observable<Customer> {
    return this.laravelCustomerService.create(customer.name, customer.lastName, customer.tel,  customer.email, customer.notes).pipe(
      map(result => {
        return new Customer(result);
      })
    );
  }

  public getCustomers(page: number, perPage: number, order: string, direction: string, filter: string): Observable<ListResult<Customer>> {
    return this.laravelCustomerService.list(page, perPage, order, direction, filter).pipe(
      map(response => {
        let data = new Array<Customer>();
        let total = 0;
        if (perPage) {
          response.data.forEach(element => {
            data.push(new Customer(element));
          });
          total = response.total;
        } else {
          response.forEach(element => {
            data.push(new Customer(element));
          });
          total = response.length;
        }
        return {
          data: data,
          total: total
        };
      })
    );
  }

  public deleteCustomer(customer: Customer): Observable<Customer> {
    return this.laravelCustomerService.delete(customer.id).pipe(
      map(result => {
        return new Customer(result);
      })
    );
  }

  public report(customer: Customer, report: string): Observable<Customer> {
    return this.laravelCustomerService.report(customer.id, report).pipe(
      map(result => {
        return new Customer(result);
      })
    );
  }

  public addAddress(address: Address): Observable<Address> {
    return this.laravelAddressService.create(address.toDTO()).pipe(
      map(result => {
        return new Address(result);
      })
    );
  }

  public updateAddress(address: Address): Observable<Address> {
    return this.laravelAddressService.update(address.toDTO()).pipe(
      map(result => {
        return new Address(result);
      })
    );
  }

  public deleteAddress(address: Address): Observable<Address> {
    return this.laravelAddressService.delete(address.id).pipe(
      map(result => {
        return new Address(result);
      })
    );
  }

  public updateNotes(customerId: number, notes: string): Observable<Customer> {
    return this.laravelCustomerService.updateNote(customerId, notes).pipe(
      map(result => {
        return new Customer(result);
      })
    );
  }
}
