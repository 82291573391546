import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { formatDateForBackend } from '../../../helpers/util';
import { OrderDTO } from './../../models/order';

@Injectable({
  providedIn: "root"
})
export class LaravelOrderService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      show: environment.baseUrl + "/api/order",
      list: environment.baseUrl + "/api/orders",
      store: environment.baseUrl + "/api/order",
      update: environment.baseUrl + "/api/order",
      delete: environment.baseUrl + "/api/order",
      ship: environment.baseUrl + "/api/order/ship",
      delivered: environment.baseUrl + "/api/order/deliver"
    };
  }

  public getById(id: number, include?: string[]): Observable<any> {
    let params = { id: "" + id };
    if (include) params["include[]"] = include;
    return this.httpClient.get(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public list(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    zoneIds?: Array<number>,
    statuses?: Array<number>,
    date?: string,
    customer_id?: number,
    deliveryman_id?: number,
    include_pick?: boolean,
    include_delivery?: boolean,
    with_sweets?: boolean,
    include_archived?: boolean,
    include?: string[]
  ): Observable<any> {
    let params = {};
    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (include) params["include[]"] = include;

    if (!include_pick) params["exclude_pick"] = true;
    if (!include_delivery) params["exclude_delivery"] = true;

    if (customer_id) params["customer_id"] = customer_id;
    if (deliveryman_id) params["deliveryman_id"] = deliveryman_id;
    if (with_sweets) params["with_sweets"] = true;
    if (include_archived) params["include_archived"] = true;

    if (zoneIds) {
      if (zoneIds.length > 0) params["zones[]"] = zoneIds;
    }
    if (statuses) {
      if (statuses.length > 0) params["statuses[]"] = statuses;
    }
    if (date) params["date"] = date;

    return this.httpClient.get(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public create(order: OrderDTO): Observable<any> {
    return this.httpClient.post(this.ROUTES.store, {
      order: order
    });
  }

  public update(order: OrderDTO): Observable<any> {
    return this.httpClient.post(this.ROUTES.update, {
      order: order
    });
  }

  public delete(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params });
  }

  public ship(deliveryManID: number, orderID: number, shippedAt: Date) {
    return this.httpClient.post(this.ROUTES.ship, {
      deliveryman_id: deliveryManID,
      order_id: orderID,
      shipped_at: formatDateForBackend(shippedAt, true)
    });
  }

  public markDelivered(orderID: number, deliveredAt: Date) {
    return this.httpClient.post(this.ROUTES.delivered, {
      order_id: orderID,
      delivered_at: formatDateForBackend(deliveredAt, true)
    });
  }
}
