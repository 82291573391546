import { Injectable, Component, Inject } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { environment } from "../../../environments/environment";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private dialog: MatDialog) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (environment["password"]) {
      const dialogRef = this.dialog.open(AuthDialog, {
        width: "300px",
        height: "320px",
        disableClose: true
      });

      return dialogRef.afterClosed();
    } else {
      return true;
    }
  }
}

@Component({
  selector: "auth-dialog",
  templateUrl: "auth-dialog.component.html"
})
export class AuthDialog {
  public password;
  public errorMessageDisplay: boolean = false;

  constructor(public dialogRef: MatDialogRef<AuthDialog>, @Inject(MAT_DIALOG_DATA) public data: string) {}

  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.check();
    }
  }

  check() {
    if (this.password === environment["password"]) {
      this.dialogRef.close(true);
    } else {
      this.errorMessageDisplay = true;
    }
  }
}
