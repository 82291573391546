
export const paths = [
    { lat: 44.163878, lng: 12.447354 }, // San Mauro a Mare - Fontana  
    { lat: 44.142709, lng: 12.473207 }, // Bellaria - Polo Est 3.0
    { lat: 44.133472, lng: 12.484582 }, // Igea Marina
    { lat: 44.108161, lng: 12.510914 }, // Torre Pedrera
    { lat: 44.091800, lng: 12.533134 }, // Viserbella
    { lat: 44.075405, lng: 12.561263 }, // Rivabella
    { lat: 44.061399, lng: 12.542094 }, // Rivabella (ponte)
    { lat: 44.047571, lng: 12.513160 }, // Spadarolo
    { lat: 44.037858, lng: 12.499500 }, // Vergiano
    { lat: 44.026560, lng: 12.481435 }, // Sant'Ermete
    { lat: 44.030222, lng: 12.470801 }, // San Martino dei Mulini
    { lat: 44.020814, lng: 12.452313 }, // Corpolò
    { lat: 44.025140, lng: 12.407972 }, // Poggio Berni
    { lat: 44.030821, lng: 12.387668 }, // Lo Stradone
    { lat: 44.055482, lng: 12.401594 }, // Canonica
    { lat: 44.080441, lng: 12.390325 }, // Savignano Sul Rubicone - Seven
    { lat: 44.115498, lng: 12.385651 }, // Gatteo (Casello)
    { lat: 44.128935, lng: 12.386775 }, // Sant'Angelo
    { lat: 44.159666, lng: 12.438859 }  // San Mauro a Mare - Karts
]