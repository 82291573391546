import { FormGroup } from '@angular/forms';

import { environment } from '../../../environments/environment';
import { Base } from './base';

export const TYPES = [
  { displayWith: 'Menu', value: 'Menu' },
  { displayWith: 'Hamburger Classici', value: 'Classic' },
  { displayWith: 'Hamburger Speciali', value: 'Special' },
  { displayWith: 'Hamburger Vegani', value: 'Veg' },
  { displayWith: 'Contorno', value: 'Side' },
  { displayWith: 'Salsa', value: 'Sauce' },
  { displayWith: 'Bevanda', value: 'Drink' },
  { displayWith: 'Dessert', value: 'Dessert' },
]

export interface ProductStatistic {
  product: ProductDTO,
  id: number,
  totalQuantity: number
}

export type ProductFamily = "cucina" | "gelato";

export const FAMILIES: ProductFamily[] = ["cucina", "gelato"];

export interface ProductDTO {
  id?: number;
  name?: string;
  price?: number;
  type?: string;
  editable?: boolean;
  photo_url?: string;
  description?: string;
  family?: ProductFamily
}
export class Product extends Base {
  private _name: string;
  private _price: number;
  private _type: string;
  private _editable: boolean;
  private _photoUrl: string;
  private _description: string;
  family?: ProductFamily

  constructor(source: any) {
    super(source);
    if (source) {
      this._name = source.name;
      this._price = source.price;
      this._type = source.type;
      this._editable = source.editable;
      this._photoUrl = source.photo_url ? environment.baseUrl + source.photo_url : null;
      this._description = source.description;
      this.family = source.family || "cucina";
    }
  }

  public toDTO(excludes?: string[]): ProductDTO {
    let dto: ProductDTO = {
      id: this.id,
      name: this.name,
      price: this.price,
      type: this.type,
      editable: this.editable,
      photo_url: this.photoUrl,
      description: this.description,
      family: this.family
    };
    return dto;
  }

  static fromFormGroup(formGroup: FormGroup): Product {
    const formModel = formGroup.value;
    let product: Product = new Product(null);
    product.name = formModel.name;
    product.price = formModel.price;
    product.type = formModel.type;
    product.description = formModel.description;
    product.editable = formModel.editable;
    product.family = formModel.family;
    // TODO: product.photoUrl = formModel.photoUrl;
    return product;
  }


  /**
   * Getter name
   * @return {string}
   */
  public get name(): string {
    return this._name;
  }

  /**
   * Getter price
   * @return {number}
   */
  public get price(): number {
    return this._price;
  }

  /**
   * Getter type
   * @return {string}
   */
  public get type(): string {
    return this._type;
  }

  /**
   * Getter editable
   * @return {boolean}
   */
  public get editable(): boolean {
    return this._editable;
  }

  /**
   * Getter photoUrl
   * @return {string}
   */
  public get photoUrl(): string {
    return this._photoUrl;
  }

  /**
   * Getter description
   * @return {string}
   */
  public get description(): string {
    return this._description;
  }

  /**
   * Setter name
   * @param {string} value
   */
  public set name(value: string) {
    this._name = value;
  }

  /**
   * Setter price
   * @param {number} value
   */
  public set price(value: number) {
    this._price = value;
  }

  /**
   * Setter type
   * @param {string} value
   */
  public set type(value: string) {
    this._type = value;
  }

  /**
   * Setter editable
   * @param {boolean} value
   */
  public set editable(value: boolean) {
    this._editable = value;
  }

  /**
   * Setter photoUrl
   * @param {string} value
   */
  public set photoUrl(value: string) {
    this._photoUrl = value;
  }

  /**
   * Setter description
   * @param {string} value
   */
  public set description(value: string) {
    this._description = value;
  }

}
