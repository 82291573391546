import { Base } from "./base";
import { FormGroup } from "@angular/forms";

export interface IngredientDTO {
  id?: number;
  name?: string;
  description?: string;
  type?: string;
  minus_price?: number;
  addition_price?: number;
}
export class Ingredient extends Base {
  private _name: string;
  private _description: string;
  private _type: string;
  private _additionPrice: number;
  private _minusPrice: number;

  constructor(source: IngredientDTO) {
    super(source);
    if (source) {
      this._name = source.name;
      this._description = source.description;
      this._type = source.type;
      this._additionPrice = source.addition_price;
      this._minusPrice = source.minus_price;
    }
  }

  public toDTO(): IngredientDTO {
    let dto: IngredientDTO = {
      id: this.id,
      name: this.name,
      description: this.description,
      type: this.type,
      addition_price: this.additionPrice,
      minus_price: this.minusPrice
    };
    return dto;
  }

  static fromFormGroup(formGroup: FormGroup): Ingredient {
    const formModel = formGroup.value;
    let ingredient: Ingredient = new Ingredient(null);
    ingredient.name = formModel.name;
    ingredient.additionPrice = formModel.additionPrice;
    ingredient.minusPrice = formModel.minusPrice;
    ingredient.type = formModel.type;
    ingredient.description = formModel.description;
    return ingredient;
  }

  /**
   * Getter name
   * @return {string}
   */
  public get name(): string {
    return this._name;
  }

  /**
   * Getter description
   * @return {string}
   */
  public get description(): string {
    return this._description;
  }

  /**
   * Getter type
   * @return {string}
   */
  public get type(): string {
    return this._type;
  }

  /**
   * Getter additionPrice
   * @return {number}
   */
  public get additionPrice(): number {
    return this._additionPrice;
  }

  /**
   * Getter minusPrice
   * @return {number}
   */
  public get minusPrice(): number {
    return this._minusPrice;
  }

  /**
   * Setter name
   * @param {string} value
   */
  public set name(value: string) {
    this._name = value;
  }

  /**
   * Setter description
   * @param {string} value
   */
  public set description(value: string) {
    this._description = value;
  }

  /**
   * Setter type
   * @param {string} value
   */
  public set type(value: string) {
    this._type = value;
  }

  /**
   * Setter additionPrice
   * @param {number} value
   */
  public set additionPrice(value: number) {
    this._additionPrice = value;
  }

  /**
   * Setter minusPrice
   * @param {number} value
   */
  public set minusPrice(value: number) {
    this._minusPrice = value;
  }
}
