import { Base } from "./base";

export interface StatusDTO {
  id?: number;
  name?: string;
}
export class Status extends Base {
  private _name: string;

  constructor(source) {
    super(source);
    if (source) {
      this.name = source.name;
    }
  }

  public toDTO(): StatusDTO {
    let dto: StatusDTO = {
      id: this.id,
      name: this.name
    };
    return dto;
  }

  /**
   * Getter name
   * @return {string}
   */
  public get name(): string {
    return this._name;
  }

  /**
   * Setter name
   * @param {string} value
   */
  public set name(value: string) {
    this._name = value;
  }
}
