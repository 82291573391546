import { Base } from "./base";
import { FormGroup } from "@angular/forms";
import { formatDateForBackend } from "../../helpers/date.helper";
import { Order } from "./order";

export interface DeliveryManDTO {
  id?: number;
  name?: string;
  last_name?: string;
  tel?: string;
  drives_own_car?: boolean;
}
export class DeliveryMan extends Base {

  private _name: string;
  private _lastName: string;
  private _tel: string;
  private _dailyOrders: Array<Order>;
  private _dailyAmount: number;

  constructor(source: any) {
    super(source);
    if (source) {
      this.name = source.name;
      this.lastName = source.last_name;
      this.tel = source.tel;
      this.dailyOrders = new Array<Order>();
      if (source.orders) {
        source.orders.forEach(order => {
          this.dailyOrders.push(new Order(order));
        });
      }
      if (this.dailyOrders.length > 0) {
        this.dailyAmount = this.dailyOrders.map(o => o.amount).reduce((totalAmount, amount) => totalAmount + Number(amount), 0);
      } else {
        this.dailyAmount = 0;
      }
    }
  }

  public toDTO(): DeliveryManDTO {
    let dto: DeliveryManDTO = {
      id: this.id,
      name: this.name,
      tel: this.tel,
      last_name: this.lastName,
    };
    return dto;
  }

  static fromFormGroup(formGroup: FormGroup): DeliveryMan {
    const formModel = formGroup.value;
    let deliveryMan: DeliveryMan = new DeliveryMan(null);
    deliveryMan.name = formModel.name;
    deliveryMan.lastName = formModel.last_name;
    deliveryMan.tel = formModel.tel;
    return deliveryMan;
  }


    /**
     * Getter name
     * @return {string}
     */
	public get name(): string {
		return this._name;
	}

    /**
     * Getter lastName
     * @return {string}
     */
	public get lastName(): string {
		return this._lastName;
	}

    /**
     * Getter tel
     * @return {string}
     */
	public get tel(): string {
		return this._tel;
	}

    /**
     * Getter dailyOrders
     * @return {Array<Order>}
     */
	public get dailyOrders(): Array<Order> {
		return this._dailyOrders;
	}

    /**
     * Getter dailyAmount
     * @return {number}
     */
	public get dailyAmount(): number {
		return this._dailyAmount;
	}

    /**
     * Setter name
     * @param {string} value
     */
	public set name(value: string) {
		this._name = value;
	}

    /**
     * Setter lastName
     * @param {string} value
     */
	public set lastName(value: string) {
		this._lastName = value;
	}

    /**
     * Setter tel
     * @param {string} value
     */
	public set tel(value: string) {
		this._tel = value;
	}

    /**
     * Setter dailyOrders
     * @param {Array<Order>} value
     */
	public set dailyOrders(value: Array<Order>) {
		this._dailyOrders = value;
	}

    /**
     * Setter dailyAmount
     * @param {number} value
     */
	public set dailyAmount(value: number) {
		this._dailyAmount = value;
	}
  

}
