import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListResult } from '../../helpers/listResult.interface';
import { Customer } from '../models/customer';
import { Order } from '../models/order';
import { LaravelOrderService } from './laravel/laravel-order.service';

@Injectable({
  providedIn: "root"
})
export class OrderService {
  constructor(private laravelOrderService: LaravelOrderService) { }

  public getOrder(id: number, include?: string | string[]): Observable<Order> {
    return this.laravelOrderService.getById(id, typeof include === "string" ? [include] : include).pipe(
      map(response => {
        return new Order(response);
      })
    );
  }

  public getOrdersByDate(date: string): Observable<ListResult<Order>> {
    return this.listOrders(null, null, null, null, null, null, date, null, null, true, true, false);
  }

  public getCustomerOrders(customer: Customer): Observable<ListResult<Order>> {
    return this.listOrders(null, null, null, null, null, null, null, customer.id, null, true, true, false);
  }

  public getDailyOrders(zoneIds?: Array<number>, statuses?: Array<number>, date?: string, deliveryman_id?: number, include_pick?: boolean, include_delivery?: boolean, include_archived?: boolean): Observable<ListResult<Order>> {
    return this.listOrders(null, null, null, null, zoneIds, statuses, date, null, deliveryman_id, include_pick, include_delivery, null, include_archived);
  }

  public getSweetsOrders(date?: string): Observable<ListResult<Order>> {
    return this.listOrders(null, null, null, null, null, null, date, null, null, true, true, true, false);
  }

  private listOrders(
    page: number,
    per_page: number,
    order: string,
    direction: string,
    zoneIds?: Array<number>,
    statuses?: Array<number>,
    date?: string,
    customer_id?: number,
    deliveryman_id?: number,
    include_pick?: boolean,
    include_delivery?: boolean,
    with_sweets?: boolean,
    include_archived?: boolean,
    include?: string | string[]
  ): Observable<ListResult<Order>> {
    return this.laravelOrderService.list(page, per_page, order, direction, zoneIds, statuses, date, customer_id, deliveryman_id, include_pick, include_delivery, with_sweets, include_archived, typeof include === "string" ? [include] : include).pipe(
      map(response => {
        let data = new Array<Order>();
        response.forEach(element => {
          data.push(new Order(element));
        });

        return {
          data: data,
          total: data.length
        };
      })
    );
  }

  public createOrder(order: Order): Observable<Order> {
    return this.laravelOrderService.create(order.toDTO()).pipe(
      map(response => {
        return new Order(response);
      })
    );
  }

  public updateOrder(order: Order): Observable<Order> {
    return this.laravelOrderService.update(order.toDTO()).pipe(
      map(response => {
        return new Order(response);
      })
    );
  }

  public deleteOrder(order: Order): Observable<Order> {
    return this.laravelOrderService.delete(order.id).pipe(
      map(result => {
        return new Order(result);
      })
    );
  }

  public shipOrder(deliveryManID: number, order: Order): Observable<Order> {
    return this.laravelOrderService.ship(deliveryManID, order.id, new Date()).pipe(
      map(result => {
        return new Order(result);
      })
    );
  }

  public deliverOrder(order: Order): Observable<Order> {
    return this.laravelOrderService.markDelivered(order.id, new Date()).pipe(
      map(result => {
        return new Order(result);
      })
    );
  }
}
