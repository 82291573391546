import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {

  private _confirmMessage: string;
  private _title: string;
  private _type: string;

  constructor(public dialogRef: MatDialogRef<DialogComponent>) { }

    /**
     * Getter confirmMessage
     * @return {string}
     */
	public get confirmMessage(): string {
		return this._confirmMessage;
	}

    /**
     * Setter confirmMessage
     * @param {string} value
     */
	public set confirmMessage(value: string) {
		this._confirmMessage = value;
	}

    /**
     * Getter title
     * @return {string}
     */
	public get title(): string {
		return this._title;
	}

    /**
     * Setter title
     * @param {string} value
     */
	public set title(value: string) {
		this._title = value;
	}

    /**
     * Getter type
     * @return {string}
     */
	public get type(): string {
		return this._type;
	}

    /**
     * Setter type
     * @param {string} value
     */
	public set type(value: string) {
		this._type = value;
	}



}
