import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class LaravelCustomerService {
  constructor(private httpClient: HttpClient) {}

  private get ROUTES() {
    return {
      show: environment.baseUrl + "/api/customer",
      list: environment.baseUrl + "/api/customers",
      store: environment.baseUrl + "/api/customer",
      delete: environment.baseUrl + "/api/customer",
      report: environment.baseUrl + "/api/customer/report",
      updateNote: environment.baseUrl + "/api/customer/updateNote"
    };
  }

  public getById(id: number): Observable<any> {
    return this.httpClient.get(this.ROUTES.show, {
      params: new HttpParams({ fromObject: { id: "" + id } })
    });
  }

  public create(name: string, last_name: string, tel: string, email: string, notes: string) {
    let customer = {
      name: name,
      last_name: last_name,
      tel: tel,
      email: email,
      notes: notes
    };
    return this.httpClient.post(this.ROUTES.store, { customer: customer });
  }

  public update(id: number, name: string, last_name: string, tel: string, email: string, notes: string) {
    let customer = {
      id: id,
      name: name,
      last_name: last_name,
      tel: tel,
      email: email,
      notes: notes
    };
    return this.httpClient.put(this.ROUTES.store, { customer: customer });
  }

  public list(page: number, per_page: number, order: string, direction: string, filter: string): Observable<any> {
    let params = {};

    if (page) params["page"] = "" + page;
    if (per_page) params["per_page"] = "" + per_page;
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (filter) params["filter"] = "" + filter;

    return this.httpClient.get(this.ROUTES.list, {
      params: new HttpParams({ fromObject: params })
    });
  }

  public delete(id) {
    let params = new HttpParams().set("id", "" + id);
    return this.httpClient.delete(this.ROUTES.delete, { params });
  }

  public report(id, report) {
    return this.httpClient.post(this.ROUTES.report, {
      id: id,
      report: report
    });
  }

  public updateNote(id, notes) {
    return this.httpClient.put(this.ROUTES.updateNote, {
      id: id,
      notes: notes
    });
  }
}
