import { Base } from "./base";
import { Product, ProductDTO } from "./product";
import { Variation, VariationDTO } from "./variation";

export interface ProductInOrderDTO {
  id?: number;
  quantity?: number;
  amount?: number;
  product_id?: number;
  product?: ProductDTO;
  variations?: VariationDTO[];
}
export class ProductInOrder extends Base {
  private _quantity: number;
  private _amount: number;
  private _product: Product;
  private _variations: Array<Variation>;

  constructor(source: any) {
    super(source);
    if (source) {
      this.quantity = source.quantity ? source.quantity : 1; // default quantity = 1
      this.amount = source.amount;
      this.product = source.product ? new Product(source.product) : null;
      this.variations = new Array<Variation>();
      if (source.variations) {
        source.variations.forEach(variation => {
          this._variations.push(new Variation(variation));
        });
      }
    }
  }

  public static same(p1: ProductInOrder, p2: ProductInOrder): boolean {
    if (p1 && p2 && Product.compare(p1.product, p2.product)) {
      return p1.variations || p2.variations ? this.sameVariations(p1.variations, p2.variations) : true;
    }
    return false;
  }

  private static sameVariations(v1s: Variation[], v2s: Variation[]): boolean {
    if (v1s && v2s && v1s.length === v2s.length) {
      if (v1s.length == 0) return true;
      return v1s.every(v1 => {
        return v2s.find(v2 => Variation.same(v1, v2)) != null;
      });
    }
    return false;
  }

  updateAmount() {
    this.amount = this.product ? this.product.price : 0;
    if (this.variations) {
      this.variations.forEach(v => {
        this.amount = Number(this.amount) + Number(v.amount);
      });
    }
    this.amount *= this.quantity;
  }

  public toDTO(excludes?: string[]): ProductInOrderDTO {
    let dto: ProductInOrderDTO = {
      id: this.id,
      amount: this.amount,
      quantity: this.quantity
    };
    if (this.product && !this.isToBeExcluded("product", excludes)) {
      dto.product_id = this.product.id;
      dto.product = this.product.toDTO();
    }
    if (this.variations && !this.isToBeExcluded("variations", excludes)) {
      dto.variations = [];
      this.variations.forEach(variation => {
        dto.variations.push(variation.toDTO());
      });
    }
    return dto;
  }

  /**
   * Getter quantity
   * @return {number}
   */
  public get quantity(): number {
    return this._quantity;
  }

  /**
   * Getter amount
   * @return {number}
   */
  public get amount(): number {
    return this._amount;
  }

  /**
   * Getter product
   * @return {Product}
   */
  public get product(): Product {
    return this._product;
  }

  /**
   * Getter variations
   * @return {Array<Variation>}
   */
  public get variations(): Array<Variation> {
    return this._variations;
  }

  /**
   * Setter quantity
   * @param {number} value
   */
  public set quantity(value: number) {
    this._quantity = value;
  }

  /**
   * Setter amount
   * @param {number} value
   */
  public set amount(value: number) {
    this._amount = value;
  }

  /**
   * Setter product
   * @param {Product} value
   */
  public set product(value: Product) {
    this._product = value;
  }

  /**
   * Setter variations
   * @param {Array<Variation>} value
   */
  public set variations(value: Array<Variation>) {
    this._variations = value;
  }
}
