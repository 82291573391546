// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  baseUrl: "https://backend-birgocena.simplenetworks.it",
  printUrl: "https://127.0.0.1:8069/print_order",
  apiUrl: "https://backend-birgocena.simplenetworks.it/api",
  gMapsApiKey: "AIzaSyAAixrp9WKjJrnb4ztxdfWIQQ1sfS0O9tE",
  password: '159birgo',
  pin: '0000',
  birgoCoords: {
    lat: 44.084297,
    long: 12.457072
  }
};
