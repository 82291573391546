export const environment = {
  production: true,
  baseUrl: "http://localhost:8000",
  printUrl: "http://192.168.1.42:8069/print_order",
  gMapsApiKey: "AIzaSyAAixrp9WKjJrnb4ztxdfWIQQ1sfS0O9tE",
  password: '159birgo',
  pin: '0000',
  birgoCoords: {
    lat: 44.084297,
    lng: 12.457072
  }
};
