import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
// import { ServiceWorkerModule } from "@angular/service-worker";
// import { environment } from "../environments/environment";

// AppModules
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// Graphics
import { MaterialModule } from "./modules/material/material.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./modules/shared/shared.module";

// Services
import { CustomerService } from "./commons/services/customer.service";
import { HttpClientModule } from "@angular/common/http";
import { OrderService } from "./commons/services/order.service";

import { registerLocaleData } from "@angular/common";
import localeIt from "@angular/common/locales/it";
import { AuthDialog } from "./commons/guards/auth.guard";
import { FormsModule } from "@angular/forms";

registerLocaleData(localeIt, "it");
@NgModule({
  declarations: [AppComponent, AuthDialog],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule
    // ServiceWorkerModule.register("/ngsw-worker.js", { enabled: environment.production })
  ],
  providers: [
    { 
      provide: LOCALE_ID, 
      useValue: "it" 
    },
    OrderService, 
    CustomerService
  ],
  bootstrap: [AppComponent],
  entryComponents: [AuthDialog]
})
export class AppModule {}
