import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './commons/guards/auth.guard';
import { NotFoundComponent } from './modules/shared/components/not-found/not-found.component';

// Shared components
const routes: Routes = [
  {
    path: '',
    loadChildren: 'app/modules/home/home.module#HomeModule',
    // canActivate: [AuthGuard]
  },
  {
    path: 'delivery',
    loadChildren: 'app/modules/delivery/delivery.module#DeliveryModule'
  },
  {
    path: 'gelataio',
    loadChildren: 'app/modules/sweets/sweets.module#SweetsModule'
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
