import { Base } from "./base";

export interface ZoneStatistic {
  id: number,
  name: string,
  totalQuantity: number
}

export interface ZoneDTO {
  id?: number;
  name?: string;
  minimum_amount?: number;
}
export class Zone extends Base {
  private _name: string;
  private _minimumAmount: number;

  constructor(source: any) {
    super(source);
    if (source) {
      this._name = source.name;
      this._minimumAmount = source.minimum_amount;
    }
  }

  public toDTO(): ZoneDTO {
    let dto: ZoneDTO = {
      id: this.id,
      name: this.name,
      minimum_amount: this.minimumAmount
    };
    return dto;
  }

  public get default(): boolean {
    return this._name == "default";
  }

  /**
   * Getter name
   * @return {string}
   */
  public get name(): string {
    return this.default ? "Non specificata" : this._name;
  }

  /**
   * Setter name
   * @param {string} value
   */
  public set name(value: string) {
    this._name = value;
  }

  /**
   * Getter minimumAmount
   * @return {number}
   */
  public get minimumAmount(): number {
    return this._minimumAmount;
  }

  /**
   * Setter minimumAmount
   * @param {number} value
   */
  public set minimumAmount(value: number) {
    this._minimumAmount = value;
  }
}
