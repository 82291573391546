import { AddressDTO } from "./address";
import { Base } from "./base";
import { Customer, CustomerDTO } from "./customer";
import { Zone, ZoneDTO } from "./zone";
import { FormGroup } from "@angular/forms";

export interface AddressDTO {
  id?: number;
  latitude?: number;
  longitude?: number;
  street?: string;
  house_number?: string;
  door_bell?: string;
  city?: string;
  zipcode?: string;
  apartment_number?: string;
  type?: string;
  zone_id?: number;
  zone?: ZoneDTO;
  customer_id?: number;
  customer?: CustomerDTO;
}

export class Address extends Base {
  private _formattedAddress: string;
  private _street: string;
  private _latitude: number;
  private _longitude: number;
  private _houseNumber: string;
  private _doorBell: string;
  private _city: string;
  private _zipcode: string;
  private _aptNumber: string;
  private _type: string;
  private _zone: Zone;
  private _customer: Customer;

  constructor(source) {
    super(source);
    if (source) {
      this._street = source.street;
      this._latitude = Number(source.latitude);
      this._longitude = Number(source.longitude);
      this._houseNumber = source.house_number;
      this._doorBell = source.door_bell;
      this._city = source.city;
      this._zipcode = source.zipcode;
      this._aptNumber = source.apartment_number;
      this._type = source.type;
      this._zone = source.zone ? new Zone(source.zone) : null;
      this._customer = source.customer ? new Customer(source.customer) : null;

      if (this.houseNumber && this.city) {
        this.formattedAddress = `${this.street}, ${this.houseNumber} ${this.city}`;
      } else {
        this.formattedAddress = `${this.street}`;
      }
    }
  }

  public toDTO(excludes?: string[]): AddressDTO {
    let dto: AddressDTO = {
      id: this.id,
      street: this.street,
      latitude: this.latitude,
      longitude: this.longitude,
      house_number: this.houseNumber,
      door_bell: this.doorBell,
      city: this.city,
      zipcode: this.zipcode,
      apartment_number: this.aptNumber,
      type: this.type
    };
    if (this.zone && !this.isToBeExcluded("zone", excludes)) {
      dto.zone_id = this.zone.id;
      dto.zone = this.zone.toDTO();
    }
    if (this.customer && !this.isToBeExcluded("customer", excludes)) {
      dto.customer_id = this.customer.id;
      dto.customer = this.customer.toDTO(["addresses"]);
    }
    return dto;
  }

  static fromFormGroup(formGroup: FormGroup): Address {
    const formModel = formGroup.value;
    let address: Address;

    if (formModel.address.latitude) {
      address = formModel.address;
      address.doorBell = formModel.doorBell;
      address.zone = formModel.zone;
    } else {
      address = new Address({ street: formModel.address });
      address.houseNumber = formModel.houseNumber;
      address.doorBell = formModel.doorBell;
      address.zone = formModel.zone;
    }

    return address;
  }

  public static fromGoogleAddress(googleData): Address {

    let result: Address = new Address(null);

    result.street = googleData.address_components.find(c => c.types[0] === "route") ? googleData.address_components.find(c => c.types[0] === "route").long_name : null;

    result.houseNumber = googleData.address_components.find(c => c.types[0] === "street_number") ? googleData.address_components.find(c => c.types[0] === "street_number").long_name : null;

    result.formattedAddress = googleData.formatted_address;

    if (googleData.types[0] == "street_address") {
      if (googleData.vicinity) {
        result.city = googleData.vicinity;
      } else {
        if (googleData.address_components.find(c => c.types[0] === "administrative_area_level_3")) {
          result.city = googleData.address_components.find(c => c.types[0] === "administrative_area_level_3").long_name;
        }
      }
    } else {
      if (googleData.address_components.find(c => c.types[0] === "administrative_area_level_3")) {
        result.city = googleData.address_components.find(c => c.types[0] === "administrative_area_level_3").long_name;
      } else {
        result.city = googleData.address_components.find(c => c.types[0] === "locality").long_name;
      }
    }


    result.zipcode = googleData.address_components.find(c => c.types[0] === "postal_code") ? googleData.address_components.find(c => c.types[0] === "postal_code").long_name : null;

    result.latitude = googleData.geometry.location.lat();
    result.longitude = googleData.geometry.location.lng();

    return result;
  }

  public get formattedAddress(): string {
    return this.latitude ? this._formattedAddress : `${this.street}, ${this.houseNumber}`;
  }

  public set formattedAddress(value: string) {
    this._formattedAddress = value;
  }

  /**
   * Getter street
   * @return {string}
   */
  public get street(): string {
    return this._street;
  }

  /**
   * Setter street
   * @param {string} value
   */
  public set street(value: string) {
    this._street = value;
  }

  /**
   * Getter latitude
   * @return {number}
   */
  public get latitude(): number {
    return this._latitude;
  }

  /**
   * Setter latitude
   * @param {number} value
   */
  public set latitude(value: number) {
    this._latitude = value;
  }

  /**
   * Getter longitude
   * @return {number}
   */
  public get longitude(): number {
    return this._longitude;
  }

  /**
   * Setter longitude
   * @param {number} value
   */
  public set longitude(value: number) {
    this._longitude = value;
  }

  /**
   * Getter houseNumber
   * @return {string}
   */
  public get houseNumber(): string {
    return this._houseNumber;
  }

  /**
   * Setter houseNumber
   * @param {string} value
   */
  public set houseNumber(value: string) {
    this._houseNumber = value;
  }

  /**
   * Getter doorBell
   * @return {string}
   */
  public get doorBell(): string {
    return this._doorBell;
  }

  /**
   * Setter doorBell
   * @param {string} value
   */
  public set doorBell(value: string) {
    this._doorBell = value;
  }

  /**
   * Getter city
   * @return {string}
   */
  public get city(): string {
    return this._city;
  }

  /**
   * Setter city
   * @param {string} value
   */
  public set city(value: string) {
    this._city = value;
  }

  /**
   * Getter zipcode
   * @return {string}
   */
  public get zipcode(): string {
    return this._zipcode;
  }

  /**
   * Setter zipcode
   * @param {string} value
   */
  public set zipcode(value: string) {
    this._zipcode = value;
  }

  /**
   * Getter aptNumber
   * @return {string}
   */
  public get aptNumber(): string {
    return this._aptNumber;
  }

  /**
   * Setter aptNumber
   * @param {string} value
   */
  public set aptNumber(value: string) {
    this._aptNumber = value;
  }

  /**
   * Getter type
   * @return {string}
   */
  public get type(): string {
    return this._type;
  }

  /**
   * Setter type
   * @param {string} value
   */
  public set type(value: string) {
    this._type = value;
  }

  /**
   * Getter zone
   * @return {Zone}
   */
  public get zone(): Zone {
    return this._zone;
  }

  /**
   * Setter zone
   * @param {Zone} value
   */
  public set zone(value: Zone) {
    this._zone = value;
  }

  /**
   * Getter customer
   * @return {Customer}
   */
  public get customer(): Customer {
    return this._customer;
  }

  /**
   * Setter customer
   * @param {Customer} value
   */
  public set customer(value: Customer) {
    this._customer = value;
  }
}
